
.slider {
    height: 100%;
    width: 100%;
}

.wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.slides {
    height: 100%;
    display: flex;
    position: relative;
    top: 0;
    left: 0;
}

.slides.vertical {
    width: 100%;
    position: absolute;
    flex-direction: column;
}

.slides.ttb {
    transform: scaleY(-1);
}

.slides.ttb > div {
    transform: scaleY(-1);
}

.slides.rtl {
    transform: scaleX(-1);
}

.slides.rtl > div {
    transform: scaleX(-1);
}

.slides.shifting {
    transition: left 1.5s ease-out;
}

.slides.shifting-vertical {
    transition: top 1.5s ease-out;
}


.slide {

    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 1s;
    position: relative;
    background: #FFCF47;
    border-radius: 2px;
}

.slider.loaded .slide:nth-child(2),
.slider.loaded .slide:nth-child(7) { background: #FFCF47 }
.slider.loaded .slide:nth-child(1),
.slider.loaded .slide:nth-child(6) { background: #7ADCEF }
.slider.loaded .slide:nth-child(3) { background: #3CFF96 }
.slider.loaded .slide:nth-child(4) { background: #a78df5 }
.slider.loaded .slide:nth-child(5) { background: #ff8686 }