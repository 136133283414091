* {
  font-family: 'Tahoma', sans-serif;
  box-sizing: border-box;
}

*::-webkit-scrollbar-track
{
  background-color: transparent;
}

*::-webkit-scrollbar
{
  width: 0.1736vw;
  height: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}


body {
  margin: 0;
  padding: 0;
}

:root {
  --background-for-block: rgba(0, 51, 161, 0.4);
  --blur-size: 1vw;
}

#root, body, html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-container video {
  object-fit: cover;
}

.layout {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-container {
  font-weight: bold;
  font-size: 1.5vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-container svg {
  font-size: 5vh;
}

/* Override */

.carousel-root {
  width: 100%;
}

.carousel .slide {
  text-align: left;
  background: transparent;
}

/* Greeting */

.greeting-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1.38889vw;
  z-index: -1;
}

.greeting-logo-wrapper {
  height: 9.259vh;
}

.greeting-logo {
  margin-top: 1.852vh;
  max-height: 5.55556vh;
  min-height: 5.55556vh;
  margin-bottom: 1.852vh;
}

.greeting-block {
  width: 17.309vw;
  padding: 1.2346vh 0.694vw;
  background: var(--background-for-block);
  backdrop-filter: blur(var(--blur-size));
  color: white;
}

.greeting-block-title {
  font-family: sans-serif;
  font-size: 2vw;
  font-weight: bold;
}

.greeting-block-date {
  font-size: 1vw;
  margin: 0.3vw 0;
}

.greeting-block-time {
  font-weight: bold;
  font-size: 1.8vw;
}

/* Background video */

.background-video-container {
  position: absolute;
  background-color: #f1f1f1;
  background-size: cover !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
}

.background-animation {
  -webkit-animation: kenburns-top-left 10s ease-in-out infinite alternate both;
  animation: kenburns-top-left 10s ease-in-out infinite alternate both
}

@-webkit-keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
    transform-origin: 16% 16%
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
    transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
    transform-origin: top left
  }
}

@keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
    transform-origin: 16% 16%
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
    transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
    transform-origin: top left
  }
}

/* Video */

.video-container {
  position: absolute;
  right: 0;
  width: 76.3889vw;
  height: 100%;
  padding-top: 9.259vh;
  margin-right: 5.208vw;
  z-index: 0;
}

.video-block {
  width: 100%;
  background: black;
  box-shadow: 0 1vw 2vw rgba(0, 0, 0, .7);
  height: 84.87654vh;
  position: relative;
}

.video-block-alternate {
  position: relative;
  background: white;
  height: 100%;
}

.video-block-alternate img {
  width: 100%;
}

.video-block-alternate.image-content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
}

.video-player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 84.87654vh;
  width: 100%;
}

.react-player iframe {
  height: 100% !important;
}

.slider-control-centerleft, .slider-control-centerright, .slider-control-bottomcenter {
  display: none;
}

/* Instagram */

.instagram-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 13.8889vw;
  height: 100%;
  display: flex;
  margin-left: 1.38889vw;
  padding-bottom: 2.284vh;
  flex-direction: column;
  justify-content: flex-end;
}

.instagram-carousel-container {
  width: 100%;
  position: relative;
}

.instagram-main-carousel, .instagram-up-carousel, .instagram-bottom-carousel {
  position: absolute;
  box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, .5);
  background: white;
  display: flex;
}

.instagram-main-carousel {
  position: relative;
  width: 100%;
  height: 13.8889vw;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.instagram-up-carousel, .instagram-bottom-carousel {
  overflow: hidden;
}

.instagram-up-carousel {
  width: 5.2083vw;
  height: 5.2083vw;
  top: -8.75%;
  right: -31.25%;
  z-index: 2;
}

.instagram-up-carousel:after {
  content: '';
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 68, 171, 0.6), rgba(0, 68, 171, 0.6));
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}

/*.instagram-up-carousel .slides.vertical {*/
/*  transform: scaleY(-1) translate3d(0px, 0px, 0px) !important;*/
/*}*/

.instagram-up-carousel .instagram-post-slide {
  height: 5.2083vw;
}

.instagram-bottom-carousel {
  width: 8.6806vw;
  height: 8.6806vw;
  right: -56.25%;
  bottom: 3.75%;
  z-index: 1;
}

.instagram-bottom-carousel:after {
  content: '';
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 68, 171, 0.4), rgba(0, 68, 171, 0.4));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.instagram-post-slide-end {
  margin-top: 6vh;
}

.slick-slider {
  height: 100%;
  width: 100%;
}

.instagram-content {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.instagram-stats {
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1.1vw;
  left: -0.6944vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  background: #FF6B00;
  padding: 0 0.3472vw;
  border-radius: 0 0.61vh 0.61vh 0;
  height: 2.778vh;
  z-index: 5;
  color: white;
}

.instagram-icon {
  height: 100%;
  margin-right: 0.3472vw;
  padding: 0.463vh 0;
}

.instagram-stats-count {
  padding: 0.463vh 0;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vh;
  line-height: 148px;
}

.instagram-post-slide {
  width: 100%;
  height: 100%;
}

.instagram-stats svg {
  margin-right: .5vw;
}

.instagram-likes {
  top: 0.6173vh;
}

.instagram-comments {
  top: 4.0126vh;
}

.instagram-image {
  width: 100%;
  background: #222222;
  background-position: center;
  background-size: cover;
}

.instagram-main-carousel:after, .instagram-up-carousel:after, .instagram-bottom-carousel:after, .instagram-image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.instagram-text-container {
  width: 100%;
  background: var(--background-for-block);
  backdrop-filter: blur(var(--blur-size));
  height: 30.89%;
  margin-top: 2.4691vh;
  padding: 0.61vh 0.3472vw;
  display: flex;
  flex-direction: column;
}

.instagram-text-header {
  margin-bottom: 0.772vh;
  position: relative;
  display: flex;
  align-items: center;
}

.instagram-text-header-name {
  color: white;
  font-size: 1.2345vh;
  margin-bottom: 0.4475vh;
  line-height: 125%;
  font-family: "SF Pro Text";
  font-weight: 600;

  width: 10.625vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instagram-text-header-logo {
  width: 4.062vw;
}

.instagram-text-photo {
  min-width: 2.22vw;
  max-width: 2.22vw;
  border-radius: 50%;
  background-size: cover !important;
  margin-right: 0.3472vw;
}

.instagram-text-photo:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.instagram-text-description {
  color: white;
  height: 80%;
  font-size: 0.8vw;
  overflow: hidden;
}

.instagram-text-description .item {
  overflow: hidden;
  height: 100% !important;
}

.instagram-text-description .carousel-root,
.instagram-text-description .carousel-slider,
.instagram-text-description .slider-wrapper,
.instagram-text-description .slider {
  height: 100% !important;
}

.instagram-text-description .instagram-post-slide {
  overflow: hidden;
}

.instagram-text-description .slide {
  height: 100%;
  padding-bottom: 10px;
}

@keyframes scroll {
  0% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(-80%);
  }
}

.instagram-text-description .slider-frame,
.instagram-text-description .slider-list,
.instagram-text-description .slider-slide {
  height: 100% !important;
}

/* Weather */

.weather-container {
  position: absolute;
  width: 17%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  margin-right: 1.3889vw;
}

.weather-block {
  width: 100%;
  height: 57.11%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.weather-card, .weather-card-active {
  width: 15.625vw;
  height: 10.8023vh;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, .4);
  transition: all .3s ease-in-out;
  padding: 1.2346vh 0.868vw 1.2346vh 0.694vw;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(var(--blur-size));
}

.weather-card-active {
  transition: all .3s ease-in-out;
  width: 18.229vw;
  height: 15.4321vh;
}

.weather-card-active .weather-card-header-city {
  padding-top: 0.755vh;
}

.weather-card-header {
  display: flex;
  justify-content: space-between;
  transition: .3s ease-in-out;
  overflow: hidden;
}


.weather-card-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.weather-card-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: .3s ease-in-out;
}

.weather-card .weather-card-header, .weather-card .weather-card-footer {
  height: 50%;
}

.weather-card-active .weather-card-header {
  height: 70%;
}

.weather-card-active .weather-card-footer {
  height: 30%;
}

.weather-card-header-city {
  font-size: 2.78vh;
  line-height: 3.349vh;
  text-transform: uppercase;
  width: 11vw;
  height: 100%;
  color: #0e3e8a;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: .3s ease-in-out;
  white-space: pre;
}

.weather-card-header-date {
  font-size: 1.85vh;
  margin-bottom: 0.3086vh;
  height: 0;
}

.weather-card-header-icon {
  transition: .3s ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.weather-card-header-icon-wrapper {
  height: 7.72vh;
  width: 4.34vw;
}

.weather-card-header-icon img {
  height: 70%;
}

.weather-card-footer-time {
  font-size: 1.8vw;
  font-weight: bold;
}

.weather-card-footer-celsius {
  font-family: 'Graphik LC Web';
  font-size: 2.5vw;
  font-weight: bolder;
}

.weather-card-header-date {
  transition: .3s ease-in-out;
}

.weather-card .weather-card-header-date {
  opacity: 0;
}

.weather-card-active .weather-card-header-date {
  height: auto;
  opacity: 1;
}

.weather-card .weather-card-header-icon {
  opacity: 0;
}

.weather-card-active .weather-card-header-icon {
  opacity: 1;
}
